* {
	margin: 0;
	padding: 0;
	border: 0;
  box-sizing: border-box;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
b {
  font-weight: 700;
}
body {
  padding: 0px;
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  overflow-x: hidden; 
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  height: 100vh;
  background-color: #E5E5E5;
}
button {
  border: none;
  background: none;
  cursor:pointer;
}
button:disabled{
  opacity: 0.2;
}
code {
  font-family: 'Source Sans Pro';
}

input:not([type="file"]), textarea { 
  text-align: left; 
  padding: 5px;
  font-size: 14px; 
  height: 30px; 
  font-family: 'Source Sans Pro';
  outline: none;
}
input[type="text"], textarea {
  font-size: 20px;
  color: #292929;
  height: 40px;
  padding: 9px 19px;
  background: #E4E5E3;
  box-shadow: inset 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
}
input[type="radio"] {
  appearance: none;
  width: 19px;
  height: 19px;
  font: inherit;
  color: #112D4E;
  background: #FFFFFF;
  border: 1px solid #112D4E;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
}
input[type="radio"]::before {
  display: none;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transition: 105ms transform ease-in-out;
  background: #2876CC;
  border: 1px solid #575A5C;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 25%);
  transform: translate(-15%, -70%);
}
input[type="radio"]:checked::before {
  display: inline-block;
}
a span {
  color: #FFFFFF;
}
.select {
  background: none;
  background-image: url('./img/arrow_down.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 0px) center;
}
.select.open {
  background-color: #415771;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
}
.round-btn img {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}
[class*="col-"] { float: left;  width: 100%; }
@media only screen and (min-width: 0px) {
  .col-s-0 { width: 0%; }
  .col-s-1 { width: 8.33%; }
  .col-s-2 { width: 16.66%; }
  .col-s-3 { width: 25%; }
  .col-s-4 { width: 33.33%; }
  .col-s-5 { width: 41.66%; }
  .col-s-6 { width: 50%; }
  .col-s-7 { width: 58.33%; }
  .col-s-8 { width: 66.66%; }
  .col-s-9 { width: 75%; }
  .col-s-10 { width: 83.33%; }
  .col-s-11 { width: 91.66%; }
  .col-s-12 { width: 100%; }
  .col-s-auto { width: auto; }
  .col-s-2-5 { width: 20.83%; }
  .col-s-3-5 { width: 29%; }
  .col-s-5-5 { width: 45.83%; }
  .col-s-9-5 { width: 79%; }
}
@media only screen and (min-width: 600px) {
  .col-m-0 { width: 0%; }
  .col-m-1 { width: 8.33%; }
  .col-m-2 { width: 16.66%; }
  .col-m-3 { width: 25%; }
  .col-m-4 { width: 33.33%; }
  .col-m-5 { width: 41.66%; }
  .col-m-6 { width: 50%; }
  .col-m-7 { width: 58.33%; }
  .col-m-8 { width: 66.66%; }
  .col-m-9 { width: 75%; }
  .col-m-10 { width: 83.33%; }
  .col-m-11 { width: 91.66%; }
  .col-m-12 { width: 100%; }
  .col-m-auto { width: auto; }
  .col-m-2-5 { width: 20.83%; }
  .col-m-3-5 { width: 30%; }
  .col-m-5-5 { width: 45.83%; }
  .col-m-9-5 { width: 79%; }
}
@media only screen and (min-width: 1170px) {
  .col-0 { width: 0%; }
  .col-1 { width: 8.33%; }
  .col-2 { width: 16.66%; }
  .col-3 { width: 25%; }
  .col-4 { width: 33.33%; }
  .col-5 { width: 41.66%; }
  .col-6 { width: 50%; }
  .col-7 { width: 58.33%; }
  .col-8 { width: 66.66%; }
  .col-9 { width: 75%; }
  .col-10 { width: 83.33%; }
  .col-11 { width: 91.66%; }
  .col-12 { width: 100%; }
  .col-auto { width: auto; }
  .col-2-5 { width: 20.83%; }
  .col-3-5 { width: 30%; }
  .col-5-5 { width: 45.83%; }
  .col-9-5 { width: 79%; }
}

.aligned-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.aligned-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.aligned-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.aligned-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.aligned-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.aligned-items-start {
  align-items: flex-start;
}
.aligned-items-start img {
  margin-top: 11px;
}
.push-right {
  margin-left: auto;
}

.preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
}
.preloader-wrapper {
  position: relative;
  height: 200px;
}
.preloader-wrapper .preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('./img/preloader.svg');
}
.page-preloader-wrapper {
  margin-top: 12%;
  position: relative;
  height: 400px;
}
.page-preloader-wrapper .preloader {
  background-image: url('./img/preloader_128px.svg');
}

.dropdown {
  display: inline-flex;
}
.dropdown .open button {
  display: block;
  background-color: #415771;
  color: #FFFFFF;
  z-index: 10;
}
.dropdown .open button:hover {
  background-color: #8d9aa9;
} 
.dropdown {
  display: inline-flex;
}
.dropdown button {
  display: none;
  width: 100%;
  height: 100%;
  text-align: left;
}
.dropdown button.selected {
  display: block;
}

/* ANIMATION */
.animated {
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-ms-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
	-ms-animation-duration: 1s;
	-o-animation-duration: 1s;
	animation-duration: 1s;
}
.animated.hinge {
	-webkit-animation-duration: 2s;
	-moz-animation-duration: 2s;
	-ms-animation-duration: 2s;
	-o-animation-duration: 2s;
	animation-duration: 2s;
}
.delay-fast-3 { -webkit-animation-delay: 0.3s; -moz-animation-delay: 0.3s; -o-animation-delay: 0.3s; animation-delay: 0.3s; }

@-webkit-keyframes fadeInUp { 0% { opacity: 0; -webkit-transform: translateY(60px); } 100% { opacity: 1; -webkit-transform: translateY(0); } }
@-moz-keyframes fadeInUp { 0% { opacity: 0; -moz-transform: translateY(60px); } 100% { opacity: 1; -moz-transform: translateY(0); } }
@-o-keyframes fadeInUp { 0% { opacity: 0; -o-transform: translateY(60px); } 100% { opacity: 1; -o-transform: translateY(0); } }
@keyframes fadeInUp { 0% { opacity: 0; transform: translateY(60px); } 100% { opacity: 1; transform: translateY(0); } }
.fadeInUp { -webkit-animation-name: fadeInUp; -moz-animation-name: fadeInUp; -o-animation-name: fadeInUp; animation-name: fadeInUp; }
/* Move */
/* LOGIN */
#login-page {
  background-color: #112D4E;
  padding-top: 140px;
  height: 100vh;
}
#login-form { 
  background-color: #FFFFFF;
  padding: 60px 60px;
  border-radius: 3px;
}
#login-form  * {
  display: block;
}
#login-form input{ 
  width: 100%;
  height: 50px;
  background: #E4E5E3;
  box-shadow: inset 1px 1px 5px 2px rgb(0 0 0 / 15%);
  border-radius: 3px;
  padding: 15px;
  font-size: 16px;
  margin-bottom: 40px;
}
.auth-form input:active,
.auth-form input:focus {
	border: 2px solid #4698F5;
  background: #E4E5E3;
}
#login-form label{ 
  padding-bottom: 10px;
  color: #112D4E;
  font-size: 11px;
  font-weight: 700;
}
#login-form button{ 
  height: 50px;
  border: 2px solid #4698F5;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
}
#login-form .errors{ 
  color: #f00;
  font-size: 13px;
  margin-bottom: 15px;
  line-height: 140%;
}
/* PAGE */
.page {
  height: 100vh;
  background: #E5E5E5;
}
.page #show-sidebar-btn {
  position: fixed;
  z-index: 100;
  height: 41px;
  width: 41px
}
.page .sidebar {
  background: #112D4E;
}
.page .sidebar input[type="radio"] {
  border: 1px solid #D3D3D3;
}
.page .sidebar-header {
  height: 70px;
  background: #375170;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
}
.page .page-content {
  height: 100%;
}
.page .page-header {
  height: 70px;
  color: #FFFFFF;
  position: fixed;
  z-index: 1; 
}
.page .main-content { 
  position: relative;
}
.page .main-content-header {
  background: rgba(17,45,78, 0.7);
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.3);
  font-weight: 700;
  font-size: 26px;
  line-height: 125%;
  color: #FDFFFC;
  padding: 13px 20px;
}
.page .header-underline {
  display: block;
  width: 40px;
  height: 3px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.main-content-header div {
  flex-grow: 1;
  height: 36px;
}
.main-content-header .header-actions a,
.main-content-header .header-actions img {
  height: 34px;
  width: 34px;
}
.main-content-header .header-actions a:not(:first-child) {
  margin-left: 15px;
}
.page.extended .page-content {
  margin-left: 0;
  width: 100%;
}
.page.extended .page-header {
  left: 0;
  width: 100%;
}
@media only screen and (max-width: 599px) {
  .page .page-header {
    display: none;
  }
  .page.extended .page-header {
    display: block;
    height: 100px;
    z-index: 1000;
    margin-top: 70px;
    background: #112D4E;
  }
  .page .sidebar-header {
    position: fixed;
    top: 0;
    justify-content: left;
    padding-left: 30px;
    z-index: 100;
  }
  .page .sidebar-content {
    margin-top: 70px;
  }
  .page.extended .main-content {
    margin-top: 170px;
  }
  .page #show-sidebar-btn {
    top: 10px;
    right: 30px;
    transform: rotate(90deg);
  }
  .page.extended #show-sidebar-btn {
    top: 18px;
    right: 34px;
    transform: rotate(270deg);
  }
  .page.extended .sidebar-content {
    display: none;
  }
  .main-content {
    padding-top: 15px;
  }
  .main-content-header {
    height: 115px;
    justify-content: flex-start;
  }
  .main-content-header > div:first-child {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 600px) {
  .page .page-content {
    margin-left: 20.83%;
  }
  .page .page-header {
    left: 20.83%;
    background: #112D4E;
  }
  .page .sidebar {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .page .main-content { 
    height: 94%; 
    padding: 25px; 
    position: relative; 
    top: 70px; 
    overflow-x: scroll; 
  }
  .main-content-header {
    height: 60px;
  }
  .page #show-sidebar-btn {
    left: 19%;
    top: 12px;
  }
  .page.extended .sidebar {
    display: none;
  }
  .page.extended #show-sidebar-btn {
    left: 25px;
    top: 16px;
    transform: rotate(180deg);
  }
  .page.extended .page-header .navbar {
    padding-left: 100px;
  }
}
/* HEADER */
.page-header a {
  color: #FFFFFF;
}
.page-header img {
  margin-right: 20px;
}
.page-header .navbar {
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.page-header ul > li:first-child,
.page-header ul > li:last-child {
  border-left: 1px solid #FFFFFF;
}
.page-header ul > li:not(:last-child) {
  border-right: 1px solid #FFFFFF;
}
.page-header .navbar li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 46px;
} 
.page-header .navbar .select {
  color: #FFFFFF;
  background-image: url('./img/arrow_down_white.svg');
  min-width: 100px;
  max-height: 400px;
}
.page-header .navbar .select.open {
  background-color: #112D4E !important;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.page-header .navbar .select.open::-webkit-scrollbar { /* Hide scrollbar for IE, Edge and Firefox */
  display: none;
}
.page-header .navbar .select button {
  height: 46px;
  color: #FFFFFF;
}
.page-header .navbar .select.open button {
  padding: 0px 5px;
  background-color: #112D4E !important;
}
.page-header .navbar .select.open button.selected {
  background-color: #0A1C31 !important;
}
.page-header .navbar .select.open button:hover {
  background-color: #415771 !important;
}
.page-header .navbar .project-navigate {
  height: 46px;
  padding: 0;
}
.page-header .navbar .project-navigate .select {
  background-position: calc(100% - 30px) center;
}
.page-header .navbar .project-navigate .select,
.page-header .navbar .project-navigate .dropdown {
  width: 100% !important;
}
.page-header .navbar .project-navigate .project-name,
.page-header .navbar .project-navigate button {
  padding-left: 30px !important;
}
@media only screen and (max-width: 599px) {
  .page-header .navbar {
    padding-left: 10px;
  }
  .page-header .navbar li {
    padding: 0px 10px;
  }
}
@media only screen and (min-width: 600px) {
  .page-header .navbar {
    padding-left: 31px;
  }
  .page-header .navbar li {
    padding: 0px 45px;
  }
}

/* NOTIFICATION */
.notifications {
  position: fixed;
  bottom: 10px;
  right: 25px;
  width: 240px;
}
.notification {
  height: 50px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
  background-color: rgba(39, 70, 101, 0.8);
  margin-bottom: 4px;
  padding: 5px;
  text-align: center;
}
.notification.error {
  color: #f00;
}
/* NOT FOUND PAGE */
#not-found-page {
  position: fixed;
  height: 100vh;
  background-color: #FFFFFF;
  background-image: url('./img/not_found.svg');
  background-repeat: no-repeat;
}
#not-found-page .panel {
  position: fixed;
  top: 0;
  width: 100px;
  height: inherit;
  background: #112D4E;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
#not-found-page .panel .top {
  position: fixed;
  top: 0;
  width: 100px;
  height: 65px;
  background: #375170;
}
#not-found-page .content > div {
  width: 100%;
  line-height: 125%;
  font-weight: 700;
  padding-left: 673px;
}
.app-name {
  font-size: 100px;
  color: #112D4E;
  margin: 80px 0px 22px 0px;
}
.error-id {
  font-size: 160px;
  color: #CFD5DC;
}
.error-name {
  font-size: 40px;
  color: #112D4E;
}
/* MESSAGE DIALOG */
#dialog-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(84, 104, 127, 0.4);
  color: #283C63;
  z-index: 50;
}
#dialog-message > div {
  width: 465px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}
#dialog-message .separator {
  height: 10px;
  background: #E5E8EC;
}
#dialog-message .title {
  font-weight: 700;
  font-size: 20px;
  padding: 22px 20px 22px 50px;
}   
#dialog-message .action,
#dialog-message .content {
  padding: 25px 50px;
}
#dialog-message .content {
  font-weight: 600;
  font-size: 20px;
  line-height: 125%;
  text-align: center;
}
#dialog-message .content span {
  color: #f00;
}
#dialog-message .action button {
  width: 60%;
  height: 50px;
  border: 2px solid #2874C8;
  border-radius: 25px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 18px;
}
/* SIDEBAR */
.sidebar-content {
  padding: 30px;
}
.sidebar-header a {
  color: #FFFFFF;
} 
.sidebar-content > div:not(:first-child) {
  border-top: 2px solid #FFB900;
}
.sidebar-content .header {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}
.sidebar-content .header img {
  margin-right: 15px;
}
.sidebar-content .search-filter {
  padding: 20px 0px;
  border-top: 2px solid #FFB900;
}
.sidebar-content .search-filter img {
  padding: 0;
  margin: 0;
  height: 17.76px;
  width: 17.76px;
}
.sidebar-content .search-filter input {
  height: 40px;
  background: #E4E5E3;
  box-shadow: inset 1px 1px 5px 2px rgba(0, 0, 0, 0.15);  
}
.sidebar-content .radio-btn-filter .header {
  background: #375170;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 8px 8px;
  height: 35px;
  padding: 0px 20px 0px 10px;
  margin: 30px 0px 5px 0px;
  border-top: 2px solid #FFB900;
}
.sidebar-content .radio-btn-filter label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin-left: 16px;
}
.sidebar-content .radio-btn-filter .radio-btn-wrapper {
  margin: 10px 0px 0px 10px;
}
.sidebar-content .radio-btn-wrapper img {
  margin-right: 15px;
  width: 19px;
  height: 19px;
}
.sidebar-content .respondents {
  background: #375170;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.sidebar-content .respondents img {
  margin-right: 15px;
}
.sidebar-content .respondents .header {
  padding: 11px 15px 0px 15px;
}
.sidebar-content .respondents ul {
  padding: 15px 45px 20px 45px;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
}
.sidebar-content .respondents ul li {
  height: 34px;
  border-bottom: 1px solid #FFFFFF;
}
/* Pulse */
.no-pulses {
  padding-top: 100px;
  font-size: 26px;
  font-weight: 600;
  color: #112D4E;
}
.pulse-row.extended-row {
  padding-bottom: 0px;
}
.pulse-row.extended-row td:last-child {
  margin-top: 10px;
  border-left: none;
  background-color: rgba(17,45,78, 0.2);
  width: 100%;
}
.pulse-row .respondents .header {
  display: none;
}
.pulse-row .respondents li {
  display: inline-flex; 
  text-align: center;
  color: #112D4E;
}
.pulse-row .respondents li span {
  height: 26px;
}
.pulse-row .respondents li span:last-child {
  font-weight: 700;
}
.pulse-row .actions a,
.pulse-row img {
  height: 34px;
  width: 34px;
}
.pulse-row .actions {
  padding-left: 3.5%
}
.pulse-row .date {
  color: rgba(17, 45, 78, 0.5)
}
.pulse-row .name {
  overflow: hidden;
}
.pulse-row .name a:last-child {
  width: calc(100% - 54px);
  max-height: 47px;
  line-height: 125%;
}
.pulse-row .name span {
  color: #112D4E;
}
.pulse-row .status span,
.pulse-row .name,
.pulse-row .date {
  font-weight: 600;
}
@media only screen and (max-width: 599px) {
  .pulses-list,
  .questions-list {
    padding: 20px 0px;
  }
  .pulse-row {
    font-size: 24px;
  }
  .pulse-row .name { 
    height: 94px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); 
    margin: 0px 30px;
  }
  .pulse-row .date-status {
    width: 75%;
    padding-left: 30px;
  }
  .pulse-row .status {
    margin-bottom: 15px;
  }
  .pulse-row .status,
  .pulse-row .date {
    line-height: 130%
  }
  .pulse-row .actions { 
    width: 25%;
    border-left: 1px solid rgba(0, 0, 0, 0.3); 
    padding-right: 30px;
    margin: 20px 0px;
    justify-content: flex-end;
  }
  .pulse-row .actions a:not(:last-child){
    margin-bottom: 15px;
  } 
  .pulse-row .respondents {
    padding: 10px 30px 10px 30px;
  }
  .pulse-row .respondents li {
    width: 100%;
    height: 60px;
    justify-content: space-between;
  }  
  .pulse-row .respondents li:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}
@media only screen and (min-width: 600px) {
  .pulses-list,
  .questions-list {
    padding: 25px;
    max-height: fit-content;
  }
  .pulse-row td {
    height: 47px !important;
  }
  .pulse-row > td:not(:first-child) {
    width: 20%;
  }
  .pulse-row > td:first-child {
    width: 40%;
  }
  .pulse-row.extended-row td:last-child {
    height: 100px !important;
  }
  .pulse-row .name,
  .pulse-row .actions {
    padding: 0px 30px;
  }
  .pulse-row .respondents {
    padding: 20px 0px;
  }
  .pulse-row .respondents li {
    width: 20%;
    justify-content: center;
  }
  .pulse-row .respondents li span {
    width: 100%;
  }
  .pulse-row .respondents li:not(:first-child) {
    border-left: 1px solid #112D4E
  }
  .pulse-row .respondents li span:last-child {
    margin-top: 8px;
  }
}
/* Questions */
.question-row table {
  padding: 0px !important;
  margin: 0px !important;
  border: 0 !important;
}
.question-row {
  padding: 17px 30px 30px 29px !important;
  color: #283C63;
  align-items: flex-start;
}
.question-row > td {
  border: none !important;
}
.question-row table tr {
  border: none;
  box-shadow: none;
  margin-bottom: 0px !important;
}
.question-row .question-img {
  margin-top: 12px;
}
.question-row .vertical-separator > span:first-child {
  border-right: 5px dashed rgba(17, 45, 78, 0.2);
}
.question-row .vertical-separator > span {
  height: 81px;
}
.question-row .horizontal-separator {
  border-top: 5px dashed rgba(17, 45, 78, 0.2);
}
.question-row .question-details {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  padding: 0px 0px 19px 0px !important;
}
.question-row .question-text,
.option-row .progress-bar,
.option-row .option-text {
  padding-left: 2%;
}
.question-row .question-index,
.option-row .percentage {
  font-weight: 700;
  color: #2876CC
}
.question-row .responses {
  font-weight: 600;
}
.option-row {
  padding: 0px !important;
  margin: 19px 0px 0px 0px !important;
}
.option-row .option-index {
  font-weight: 600;
  color: #112D4E;
}
.option-row .progress-bar div {
  height: 24px;
  border: 1px solid #CCCCCC;
  padding: 0px !important;
}
.option-row .progress-bar span {
  height: 18px;
  margin: 0px 3px;
  background: #2876CC;
}
.option-row .prediction {
  margin-top: 14px;
  color: #A0ABB8;
}
.option-row .progress-bar .prediction span {
  background: #A0ABB8;
}
@media only screen and (max-width: 599px) {
  .question-row .question-img {
    display: none;
  }
  .question-row .question-index,
  .question-row .option-index {
    margin-bottom: 10px;
  }
  .question-row .progress-bar {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .question-row > td:last-child {
    width: 95.95%;
  }
  .question-row > td:first-child {
    width: 4.05%;
  }
  .question-row .question-details {
    height: 81px !important;;
  }
  .option-row {
    min-height: 24px;
    max-height: 61px;
  }
}
/* Table */
.table-wrapper table {
  overflow-x: hidden;
  font-size: 20px;
}
.table-wrapper tr {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  background: #FFFFFF;
}
.table-wrapper td {
  height: 100%;
}
.table-wrapper .pagination {
  align-items: flex-start;
}
.table-wrapper .pagination label {
  color: #112D4E; 
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.01em;
}
.table-wrapper .pagination .select {
  width: 40px;
  color: #112D4E; 
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-left: 13px;
}
.table-wrapper .pagination .select.open button {
  padding: 5px;
  height: 30px;
  font-weight: 500;
}
.table-wrapper .pagination .arrow-left {
  margin: 0px 30px 0px 15px;
}
@media only screen and (max-width: 599px) {
  .table-wrapper .pagination {
    padding-right: 30px;
  }
}
@media only screen and (min-width: 600px) {
  .table-wrapper tr {
    padding: 17px 0px 16px 0px;
  }
  .table-wrapper td:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.3);
  }
  .question-row table td:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
  .table-wrapper .pagination .select {
    margin-right: 45px;
  }
}
/* Editor */
#editor .sidebar .preloader{
  background-image: url('./img/preloader_white.svg');
}
.pulse-actions {
  margin-bottom: 30px;
}
.pulse-actions ul {
  height: 80px;
  background: #375170;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.pulse-actions ul a {
  padding: 0px 20px;
}
.pulse-actions img {
  margin-top: 5px;
  height: 34px;
  width: 34px;
}
.pulse-actions li {
  height: 46px;
}
.pulse-actions li:not(:first-child):not(:last-child) {
  border-left: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
}
.display-page-filter label[for="description"] img {
  width: 22px;
  margin-right: 12px;
}
.container {
  padding: 25px 50px 25px 50px;
}
.container-inner {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}
#pulse-description .container-inner {
  padding: 57px 0px 30px 0px;
}
#pulse-description .pulse-info {
  padding-right: 33px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
#pulse-description .main-info {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
#pulse-description .additional-info {
  padding-top: 30px;
}
#pulse-description .main-info,
#pulse-description .additional-info {
  padding-left: 30px;
  padding-right: 12px;
}
#pulse-description .main-info > div:not(:last-child),
#pulse-description .additional-info > div:not(:last-child) {
  margin-bottom: 25px;
}
#pulse-description #description {
  height: 105px;
}
#pulse-description .pulse-image {
  padding: 8px 10% 0px 10%;
}
#pulse-description .pulse-image .image-preview {
  border: 1px solid #112D4E;
  height: 274px;
  width: 100%;
  margin-bottom: 40px;
}
#pulse-description .pulse-image .btn {
  cursor: pointer;
}
#pulse-description .pulse-image input {
  height: 0px;
  visibility: hidden;
}
#pulse-description .pulse-image span {
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #022F54;
  opacity: 0.5;
}
#pulse-advanced-options .settings-block:not(:last-child) {
  border-bottom: 10px solid #E5E8EC;;
}
#pulse-advanced-options .radio-group {
  margin-bottom: 50px;
}
#pulse-advanced-options .radio-group .header {
  font-weight: 700;
  font-size: 20px;
  color: #112D4E;
  margin-bottom: 10px;
}
#pulse-advanced-options .radio-group label {
  width: 15px;
}
#pulse-advanced-options .radio-group input {
  margin-right: 20px;
}
#pulse-advanced-options .radio-group .radio-btn-wrapper {
  padding-top: 10px;
}
#pulse-advanced-options .settings-block > div:nth-child(2) {
  border-left: 3px solid rgba(229,232,236, 0.3);
  padding: 30px 40px;
}
#pulse-advanced-options #question-text {
  height: 87px;
}
#pulse-advanced-options #landing-page-text {
  height: 157px;
}
#pulse-advanced-options .location {
  margin-right: 65px;
}
#pulse-advanced-options .dropdown-wrapper {
  height: 50px;
  background: #C5C5C5;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
  padding: 3px;
}
#pulse-advanced-options .dropdown {
  width: 100%;
}
#pulse-advanced-options .select {
  width: 100%;
  height: 42px;
  font-weight: 400;
  font-size: 20px;
  color: #878786;
  background-color: #FFFFFF;
  background-position: calc(100% - 10px) center;
  border: 4px solid #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}
#pulse-advanced-options .select button {
  padding-left: 20px;
}
#editor-simple .separator {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
#editor-simple .question {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  padding: 30px;
}
#editor-simple .question .round-btn img {
  height: 34px;
  width: 34px;
}
#editor-simple .question-index,
#editor-simple .option-index {
  color: #112D4E;
  font-weight: 600;
}
#editor-simple .question-index img,
#editor-simple .option-index img {
  margin-right: 15px;
}
#editor-simple .question-index {
  font-size: 24px;
}
#editor-simple .option-index {
  font-size: 20px;
}
#editor-simple  #question-text {
  height: 60px;
}
#editor-simple  #option-text {
  height: 40px;
}
#editor-simple .question > div:first-child {
  margin-bottom: 30px;
}
#editor-simple .option {
  margin-top: 30px;
}
#editor-advance .graph-container {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  padding: 30px;
  min-height: 638px;
}
#editor-advance .zoom-in {
  margin-right: 8px;
}
#editor-advance .zoom-out {
  margin: 0px 8px;
}
#editor-advance .fit-graph-wrapper {
  height: 34px;
  width: 50px;
  margin: 0px 0px 4.5px 8px;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 40%);
}
#editor-advance .fit-graph {
  height: 32px;
  width: 48px;
  border: 2px solid #2772C3;
  border-radius: 12px;
  font-size: 22px;
}
.graph-container .custom-node {
  fill: #ECF5FE;
  stroke-width: 1px;
  stroke: #112D4E;
} 
.graph-container [class*="custom-node"][class*='Node-module_active'] {
  stroke: #112D4E !important;
  fill: #FFFFFF
}
.graph-container .custom-edge {
  stroke: #4698F5;
  stroke-width: 2px;
}
.graph-container [class*="custom-edge"][class*='Edge-module_active'] {
  stroke: #112D4E !important;
}
.graph-container .custom-port {
  fill: #415771;
  stroke: #415771;
}
.graph-container .custom-arrow {
  fill: #4698F5;
}
.graph-container text {
  fill: #112D4E
}
.element-editor-container {
  position: fixed;
  top: 180px;
  right: 53px;
  width: 350px;
  height: 162px;
}
.element-editor-container > div {
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #112D4E;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 10px;
}
.element-editor-container .header {
  font-weight: 600;
  font-size: 14px;
  margin: 0px 0px 9px 15px;
}
.element-editor-container textarea {
  height: 100px;
  font-size: 14px;
  padding: 8px 15px !important;
}
.element-editor-container .actions {
  padding: 5px 0px;
  width: 15%;
}
.element-editor-container .actions img {
  height: 33px;
}
.element-editor-container .separator {
  height: 10px;
  width: 100%;
  background: #E5E8EC;
}
@media only screen and (max-width: 599px) {
  .container {
    padding: 25px 0px;
  }
  #editor-simple, 
  #editor-advance {
    padding: 25px 0px;
  }
  #editor-simple .question {
    margin-bottom: 20px;
  }
  #editor-simple .question-index {
    margin-bottom: 15px;
  }
  #editor-simple .question-actions > div,
  #editor-simple .option-actions > div {
    justify-content: flex-end;
  }
  #editor-simple .option > div:first-child {
    margin-bottom: 10px;
  }
  #pulse-description .additional-info {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 30px;
  }
  #pulse-description .pulse-image {
    padding-top: 30px;
  }
  .settings-block .img-wrapper {
    height: 75px;
    border-bottom: 3px solid rgba(229,232,236, 0.3);
  }
}
@media only screen and (min-width: 600px) {
  .container {
    padding: 25px 50px;
  }
  #editor-simple, 
  #editor-advance {
    padding: 25px;
  }
  #editor-simple .question {
    margin-bottom: 30px;
  }
  #editor-simple .option > div:first-child {
    padding-left: 45px;
  }
  #pulse-advanced-options .settings-block .radio-group:not(:first-child) {
    padding-left: 50px;
  }
  #pulse-advanced-options .radio-group.border {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
}
